/*
@contract footer {
  margin: Margin
  background: BackgroundColor
  color: Color
  heading-label-color: Color
  logo-color: Color
  social-icon {
    background: BackgroundColor
    background-hover: BackgroundColor
    color: Color
    size: Height
  }
  anchor-text {
    color: Color
    color-hover: Color
    color-active: Color
  }
  link-section {
    chevron-icon {
      color: Color
    }
  }
  elements {
    flex-direction: FlexDirection
  }
  bottom {
    flex-direction: FlexDirection
    justify-content: JustifyContent
    width: Width
    padding-bottom: Padding
    gap: Gap
  }
  middle {
    flex-direction: FlexDirection
    gap: Gap
  }
  top {
    padding: Padding
  }
}
*/

.footer {
  --heading-with-heading-font-font-weight: bold;
  --anchor-text-color-default: var(--color-gray-200);
  --anchor-text-color-hover: var(--color-gray-200);
  --anchor-text-color-active: var(--color-gray-400);
  --logo-color: var(--color-white);
  background-color: var(--footer-background);
  color: var(--footer-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  content-visibility: auto;
  contain-intrinsic-size: auto 600px;
}

.footerTop {
  padding: var(--footer-top-padding);
  display: flex;
  justify-content: flex-start;
  gap: var(--space-8x);
  flex-direction: column;
  font-size: var(--font-size-body);
  width: 100%;
  max-width: 342px;

  @media (--screen-md) {
    max-width: 720px;
  }
}

.footerLogo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: var(--footer-logo-color);
  max-width: var(--footer-logo-max-width);
  height: var(--space-10x);
}

.footerMiddle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--space-8x);

  @media (--screen-md) {
    gap: var(--footer-middle-gap);
    flex-direction: var(--footer-elements-flex-direction);
  }

  @media (--screen-xl) {
    gap: 66px;
    flex-direction: row;
  }
}

.footerLinksLeft {
  --heading-text-align: left;
  --heading-padding: 0;
  --anchor-weight: 200;
  display: flex;
  gap: 66px;
  justify-content: space-between;
}

.footerLinksRight {
  display: flex;
  flex-direction: column;
  gap: var(--space-2-5x);
  text-align: left;
  max-width: 312px;
}

.footerLinksDownloadApp {
  font-weight: var(--font-weight-medium);
  line-height: var(--font-size-body);
}

.footerLinksApps {
  --anchor-display: flex;
  --anchor-gap: var(--space-1x);
  --anchor-padding: var(--space-2x) var(--space-4x);
  --anchor-placement: center;
  display: flex;
  gap: var(--space-2x);
}

.footerLinksApp {
  font-size: var(--font-size-2xl);
  display: flex;
  border: 1px solid var(--color-white);
  height: var(--space-10x);
  border-radius: var(--space-2x);
}

.footerLinksAppText {
  font-size: var(--font-size-body);
}

.footerLinksSocial {
  --footer-social-icon-background: var(--color-white);
  --footer-social-icon-color: var(--color-black);
  --footer-social-icon-background-hover: var(--color-gray-200);
  --footer-social-icon-background-active: var(--color-gray-400);
}

.footerLinksAccessibility,
.footerDisclaimer {
  font-size: var(--font-size-xs);
}

.footerDisclaimer {
  display: flex;
  width: 312px;

  @media (--screen-md) {
    width: 720px;
  }
}

.footerBottomWrapper {
  display: flex;
  justify-content: center;
  font-size: var(--font-size-sm);
  width: 100%;
  padding: var(--space-3x) var(--space-6x) var(--footer-padding-bottom)
    var(--space-6x);
  background-color: var(--color-gray-600);
}

.footerBottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 312px;
  gap: var(--footer-bottom-gap);

  @media (--screen-md) {
    flex-direction: var(--footer-elements-flex-direction);
    justify-content: var(--footer-bottom-justify-content);
    width: var(--footer-bottom-width);
  }

  @media (--screen-xl) {
    flex-direction: row;
    justify-content: center;
    width: 720px;
  }
}

.footerCopyrightGroup,
.footerCopyrightAnchors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-3x);
}

.footerCopyrightAnchors {
  text-decoration: underline;
}

.footerEqualHousing {
  display: flex;
  justify-content: center;
  gap: var(--space-4x);
}

.footerEqualHousingOpportunityAnchor {
  min-width: 34px;
  min-height: 34px;
}

.footerEqualHousingContent {
  display: flex;
  gap: var(--space-4x);
}

.footerDisclosures {
  display: flex;
  flex-direction: column;
  gap: var(--space-8x);
}

.footerEqualHousingCopy {
  font-size: 13px;
}

.footerFairHousingAnchor {
  text-decoration: underline;
}
